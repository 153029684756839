<div fxLayout="column" id="login">
  <div fxLayout="column" fxLayoutAlign="center center" id="login-form-wrapper">
    <div id="login-form">
      <div class="logo">
        <img src="assets/py-logo-horizontal.png" alt="logo" />
      </div>

      <div class="title">
        {{ titleText }}
      </div>

      <form [formGroup]="loginForm" name="loginForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput />
          <mat-icon [color]="color" matSuffix>email</mat-icon>
          <mat-error *ngIf="loginForm.get('email')?.hasError('required')">
            {{ emailErrorRequiredText }}
          </mat-error>
          <mat-error
            *ngIf="
              !loginForm.get('email')?.hasError('required') &&
              loginForm.get('email')?.hasError('email')
            "
          >
            {{ emailErrorPatternText }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            formControlName="password"
            matInput
            type="password"
          />
          <mat-icon [color]="color" matSuffix>lock</mat-icon>
          <mat-error>
            {{ passwordErrorRequiredText }}
          </mat-error>
        </mat-form-field>

        <button
          (click)="login()"
          [color]="colorAccent"
          [disabled]="loginForm.invalid"
          aria-label="LOG IN"
          class="submit-button"
          id="loginButton"
          mat-raised-button
        >
          {{ loginButtonText }}
        </button>
      </form>
    </div>
  </div>
</div>
