import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'pidelo-ya-cms';

  constructor(private auth: AngularFireAuth) {}

  get currentUser(): Observable<firebase.User> {
    return this.auth.user;
  }
}
