import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() messageOnAuthSuccess: string;
  @Input() messageOnAuthError: string;

  // i18n
  @Input() titleText = 'Ingresa a tu cuenta';
  @Input() loginButtonText = 'LOGIN';

  // i18n email
  @Input() emailText = 'Email';
  @Input() emailErrorRequiredText = 'Email is required';
  @Input() emailErrorPatternText = 'Please enter a valid email address';

  // i18n password
  @Input() passwordText = 'Password';
  @Input() passwordErrorRequiredText = 'Password is required';

  loginForm: FormGroup;
  authProviders = {};
  onErrorSubscription: Subscription;
  authenticationError = false;

  constructor(
    private snackBar: MatSnackBar,
    public auth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  get color(): string | ThemePalette {
    return this.authenticationError ? 'warn' : 'primary';
  }

  get colorAccent(): string | ThemePalette {
    return this.authenticationError ? 'warn' : 'accent';
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  getMessageOnAuthError(error: any): string {
    return (
      error.toString() || 'Sorry, something went wrong. Please retry later.'
    );
  }

  async login(): Promise<void> {
    try {
      const signInResult = await this.auth.signInWithEmailAndPassword(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      );
      console.log(`SignIn Success`, signInResult.user);
      this.snackBar.open('Welcome!', null);
      this.router.navigate(['business']);
    } catch (error) {
      console.log(`SignIn Error: ${error}`);
      this.snackBar.open(this.getMessageOnAuthError(error), null, {
        duration: 500,
      });
    }
  }
}
